import './bootstrap';
import * as Sentry from "@sentry/browser";

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
      navigator.serviceWorker.register("./serviceworker.js", { scope: "./" })
          .then(registration => {
              console.log('Service Worker зарегистрирован:', registration);
          })
          .catch(error => {
              console.log('Ошибка при регистрации Service Worker:', error);
          });
  });
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE || "production",
  release: import.meta.env.VITE_SENTRY_RELEASE || "1.0.0",
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  replaysSessionSampleRate: 1.0, 
  replaysOnErrorSampleRate: 1.0,
});


const metaRequestId = document.querySelector('meta[name="x-request-id"]');
const requestId = metaRequestId ? metaRequestId.getAttribute('content') : null;

function setRequestIdTag(requestId) {
  if (!requestId) {
    console.warn("requestId не определён.");
    return;
  }
  Sentry.setTag("X-Request-ID", requestId);
}


setRequestIdTag(requestId);


// resources/js/app.js